import React from 'react'
import * as O from 'fp-ts/Option'
import * as RA from 'fp-ts/ReadonlyArray'
import { flow, pipe, unsafeCoerce } from 'fp-ts/function'
import LoadingError from '../../components/LoadingError'
import { UnitConversion, UnitConversionsQuery } from '../../graphql/gateway/types'
import { UnitConversionsContext } from './UnitConversionsContext'
import { LoadingCircular } from '../../components/LoadingCircular'
import { useUnitConversionsQuery } from '../../graphql/gateway/queries/GetUnitConversions.generated'

export function handleQueryData({ unitConversions }: UnitConversionsQuery): ReadonlyArray<UnitConversion> {
  return pipe(unitConversions.entities, RA.map(unsafeCoerce<any, UnitConversion>))
}

const UnitConversionsContextProvider: React.FC = ({ children }) => {
  const [error, setError] = React.useState<O.Option<Error>>(O.none)
  const [units, setUnits] = React.useState<ReadonlyArray<UnitConversion>>([])

  const { loading } = useUnitConversionsQuery({
    fetchPolicy: 'cache-first',
    onError: flow(O.some, setError),
    onCompleted: flow(handleQueryData, setUnits),
  })

  if (loading) {
    return <LoadingCircular />
  }

  if (O.isSome(error)) {
    return <LoadingError errorText={error.value.message} />
  }

  return <UnitConversionsContext.Provider value={units}>{children}</UnitConversionsContext.Provider>
}

export default UnitConversionsContextProvider
