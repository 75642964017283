import React from 'react'
import * as O from 'fp-ts/Option'
import * as RA from 'fp-ts/ReadonlyArray'
import { flow, pipe, unsafeCoerce } from 'fp-ts/function'
import LoadingError from '../../components/LoadingError'
import { GetUnitsQuery, Unit } from '../../graphql/gateway/types'
import { UnitsContext } from './UnitsContext'
import { useGetUnitsQuery } from '../../graphql/gateway/queries/GetUnits.generated'
import { LoadingCircular } from '../../components/LoadingCircular'

function handleQueryData({ units }: GetUnitsQuery): ReadonlyArray<Unit> {
  return pipe(units.entities, RA.map(unsafeCoerce<any, Unit>))
}

const UnitsContextProvider: React.FC = ({ children }) => {
  const [error, setError] = React.useState<O.Option<Error>>(O.none)
  const [units, setUnits] = React.useState<ReadonlyArray<Unit>>([])

  const { loading } = useGetUnitsQuery({
    fetchPolicy: 'cache-first',
    onError: flow(O.some, setError),
    onCompleted: flow(handleQueryData, setUnits),
    variables: {
      filterOperatorType: 'OR',
      filter: [
        {
          key: 'tags',
          type: 'IN',
          value: 'SaleUnit',
        },
        {
          key: 'tags',
          type: 'IN',
          value: 'AreaUnit',
        },
        {
          key: 'tags',
          type: 'IN',
          value: 'VolumeUnit',
        },
        {
          key: 'tags',
          type: 'IN',
          value: 'LengthUnit',
        },
        {
          key: 'tags',
          type: 'IN',
          value: 'WeightUnit',
        },
      ],
    },
  })

  if (loading) {
    return <LoadingCircular />
  }

  if (O.isSome(error)) {
    return <LoadingError errorText={error.value.message} />
  }

  return <UnitsContext.Provider value={unsafeCoerce(units)}>{children}</UnitsContext.Provider>
}

export default UnitsContextProvider
