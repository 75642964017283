import _ from 'lodash'
import { createTheme } from '@material-ui/core/styles'
import { palette, theme as parent } from './theme'
import { SimplePaletteColorOptions } from '@material-ui/core/styles/createPalette'
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides'
import { AutocompleteClassKey } from '@material-ui/lab'
import { CSSProperties } from '@material-ui/styles'
import { typography } from './theme-v2-definitions'
import { Theme } from '@material-ui/core'
import { calc } from './utils/css-calc'

interface Overrides extends CoreOverrides {
  // Define additional lab components here as needed....
  MuiAutocomplete?: Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>> | undefined
}

const parentTheme = parent
const defaultTheme = parent

const labOverrides: Overrides = {
  MuiAutocomplete: {
    root: {
      borderTopLeftRadius: 2,
      borderTopRightRadius: 2,
      '&:focus-within': {
        outlineColor: palette.primary.main,
        outlineStyle: 'auto',
        outlineWidth: 1,
      },
    },
    input: {
      '&:focus': {
        outlineWidth: 0,
      },
    },
    inputRoot: {
      '&:first-child': {
        paddingLeft: 12,
        paddingTop: 5,
        paddingBottom: 6,
      },
      '&::placeholder': {
        color: '#979797',
        fontWeight: 'normal',
        opacity: 1,
      },
    },
  },
}

const theme: Theme = createTheme({
  ...parentTheme,
  typography,
  overrides: {
    ...labOverrides,
    ...parentTheme.overrides,
    MuiLink: {
      root: {
        cursor: 'pointer',
        color: (parentTheme.palette?.secondary as SimplePaletteColorOptions)?.main,
      },
    },
    MuiTable: {
      ...parentTheme.overrides?.MuiTable,
      root: {
        ...parentTheme.overrides?.MuiTable?.root,
        color: parentTheme.palette?.text?.secondary,
        background: '#fff',
        fontSize: '1rem',
        border: undefined,
        [defaultTheme.breakpoints.down('md')]: {
          padding: undefined,
        },
      },
    },
    MuiTableBody: {
      ...parentTheme.overrides?.MuiTableBody,
      root: {
        ...parentTheme.overrides?.MuiTableBody?.root,
        border: `1px solid ${parentTheme.palette?.divider}`,
        background: '#FFF',
      },
    },
    MuiTableRow: {
      ...parentTheme.overrides?.MuiTableRow,
      root: {
        ...parentTheme.overrides?.MuiTableRow?.root,
        borderBottom: undefined,
      },
    },
    MuiTableCell: {
      ...parentTheme.overrides?.MuiTableCell,
      root: {
        ...parentTheme.overrides?.MuiTableCell?.root,
        fontSize: undefined,
        [defaultTheme.breakpoints.down('md')]: {
          padding: undefined,
        },
      },
      head: {
        ...parentTheme.overrides?.MuiTableCell?.head,
        lineHeight: 'inherit',
        paddingTop: 10,
        paddingBottom: 10,
      },
      body: {
        ...parentTheme.overrides?.MuiTableCell?.body,
        fontWeight: undefined,
        color: undefined,
      },
    },
    MuiInputLabel: {
      ...parentTheme.overrides?.MuiInputLabel,
      root: {
        ...parentTheme.overrides?.MuiInputLabel?.root,
        lineHeight: '20px',
        fontSize: '1rem',
        fontWeight: 400,
        color: parentTheme.palette?.text?.secondary,
        marginBottom: defaultTheme.spacing(-2),
      },
    },
    MuiInputBase: {
      ...parentTheme.overrides?.MuiInputBase,
      root: {
        ...parentTheme.overrides?.MuiInputBase?.root,
        fontSize: '0.875rem',
        fontWeight: undefined,
        color: parentTheme.palette?.text?.primary,
        backgroundColor: '#f9f9f9',
      },
      input: {
        ...parentTheme.overrides?.MuiInputBase?.input,
        backgroundColor: undefined,
      },
    },
    MuiInput: {
      ...parentTheme.overrides?.MuiInput,
      underline: {
        ...parentTheme.overrides?.MuiInput?.underline,
        '&:before': {
          borderBottomColor: (parentTheme.palette?.text as any)?.tertiary,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: (parentTheme.palette?.secondary as SimplePaletteColorOptions)?.main,
        },
      },
    },

    MuiButton: {
      ...parentTheme.overrides?.MuiButton,
      root: {
        ...parentTheme.overrides?.MuiButton?.root,
        fontWeight: 600,
        borderRadius: 4,
      },
      sizeLarge: {
        ...parentTheme.overrides?.MuiButton?.sizeLarge,
        fontSize: '1rem',
      },
      text: {
        ...parentTheme.overrides?.MuiButton?.text,
        color: parentTheme.palette?.text?.secondary,
      },
    },
    MuiIconButton: {
      edgeEnd: {
        margin: '0 !important',
      },
    },
    MuiSvgIcon: {
      ...parentTheme.overrides?.MuiSvgIcon,
      root: {
        ...parentTheme.overrides?.MuiSvgIcon?.root,
        fontSize: '1.25rem',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(35, 39, 53, 0.8)',
      },
    },
    MuiChip: {
      ...parentTheme.overrides?.MuiChip,
      root: {
        ...parentTheme.overrides?.MuiChip?.root,
        backgroundColor: (parentTheme.palette?.secondary as SimplePaletteColorOptions)?.light,
        color: parentTheme.palette?.text?.secondary,
        fontSize: '0.75rem',
        height: 20,
      },
      label: {
        ...parentTheme.overrides?.MuiChip?.label,
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    MuiDialog: {
      ...parentTheme.overrides?.MuiDialog,
      paper: {
        ...parentTheme.overrides?.MuiDialog?.root,
        borderRadius: 4,
      },
    },
    MuiDialogTitle: {
      root: {
        color: parentTheme.palette?.text?.primary,
        fontWeight: 'bold',
        paddingTop: 18,
        paddingBottom: 5,
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiDialogContentText: {
      root: {
        color: parentTheme.palette?.text?.primary,
        fontSize: '0.875rem',
      },
    },
    MuiDialogActions: {
      root: {
        padding: 24,
        paddingTop: 12,
      },
    },
    MuiMobileStepper: {
      dot: {
        margin: '0 4px',
        backgroundColor: '#cce4f9',
      },
      dotActive: {
        backgroundColor: (parentTheme.palette?.secondary as SimplePaletteColorOptions)?.main,
      },
    },
  },
})

_.set(theme, 'calc', calc)
_.set(theme, 'pxToRem', (factor) => `${0.25 * factor}rem`)
_.set(theme, 'spacing', (factor) => `${0.875 * factor}rem`)
_.set(theme, 'print', (cssDeclarations) => {
  return {
    '@media print': {
      ...cssDeclarations,
    },
  }
})

export default theme
