import * as Types from '../types.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {"context":{"gateway":true}}
export type GetUnitsQueryVariables = Types.Exact<{
  filterOperatorType?: Types.InputMaybe<Types.OperatorType>;
  filter: Array<Types.GenericFilter> | Types.GenericFilter;
  sorting?: Types.InputMaybe<Array<Types.Sorting> | Types.Sorting>;
  paging?: Types.InputMaybe<Types.Pagination>;
}>;


export type GetUnitsQuery = { __typename?: 'Query', units: { __typename?: 'ManyUnitResult', entities: Array<{ __typename?: 'Unit', id: string, name: string, tags: string }> } };


export const GetUnitsDocument = gql`
    query GetUnits($filterOperatorType: OperatorType, $filter: [GenericFilter!]!, $sorting: [Sorting!], $paging: Pagination) {
  units(
    filterOperatorType: $filterOperatorType
    filter: $filter
    sorting: $sorting
    paging: $paging
  ) {
    entities {
      id
      name
      tags
    }
  }
}
    `;

/**
 * __useGetUnitsQuery__
 *
 * To run a query within a React component, call `useGetUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitsQuery({
 *   variables: {
 *      filterOperatorType: // value for 'filterOperatorType'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetUnitsQuery(baseOptions: Apollo.QueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
      }
export function useGetUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnitsQuery, GetUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnitsQuery, GetUnitsQueryVariables>(GetUnitsDocument, options);
        }
export type GetUnitsQueryHookResult = ReturnType<typeof useGetUnitsQuery>;
export type GetUnitsLazyQueryHookResult = ReturnType<typeof useGetUnitsLazyQuery>;
export type GetUnitsQueryResult = Apollo.QueryResult<GetUnitsQuery, GetUnitsQueryVariables>;