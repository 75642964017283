import _ from 'lodash'
import React from 'react'
import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Select, { SelectExternalProps } from './Select/Select'
import { getCountryName } from '../utils/getCountryName'
import type { Country } from '../graphql/api/types'

interface SelectCountryProps extends SelectExternalProps {
  readonly value: string
  readonly defaultCode?: string
  readonly countryCode?: Maybe<string>
  readonly countries: ReadonlyArray<Country>
  readonly setCountryName?: (name: string) => void
  readonly setCountryCode?: (name: string) => void
}

const SelectCountry: React.FC<SelectCountryProps> = ({
  label,
  defaultCode,
  countries,
  setCountryName = _.noop,
  setCountryCode = _.noop,
  ...props
}) => {
  const { t } = useTranslation()
  const { value: selectedValue, onChange } = props

  React.useEffect(() => {
    if (!selectedValue) {
      return
    }

    const country = _.find(countries, { id: selectedValue })

    if (_.isNil(country)) {
      return
    }

    setCountryName(country.name)
    setCountryCode(country.countryCode)
  }, [countries, selectedValue, setCountryName, setCountryCode])

  React.useEffect(() => {
    if (!defaultCode) {
      return
    }

    const defaultCountry = _.find(countries, (country) => {
      return country.countryCode === defaultCode
    })

    if (!defaultCountry || !onChange) {
      return
    }

    onChange({
      target: {
        value: defaultCountry.id,
      },
    } as React.ChangeEvent<{ value: string }>)
  }, [countries, defaultCode, onChange])

  return (
    <Select
      id="countrySelector"
      className="selectCountry"
      label={label || t('country.name')}
      name="countrySelector"
      MenuProps={{ MenuListProps: { disablePadding: true, id: 'countrySelectorMenu' } }}
      {...props}
      value={props.value}
    >
      {_.map(countries, (country) => (
        <MenuItem key={country.id} value={country.id}>
          {getCountryName(country, t)}
        </MenuItem>
      ))}
    </Select>
  )
}

export default SelectCountry
