import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Snackbar, SnackbarContent } from '@material-ui/core'

import { SIDEBAR_NAVIGATION_WIDTH } from '../Navigation/constants'

type LinkNotificationProps = {
  readonly message: string
  readonly route: string
  readonly onClose: () => void
}

const LinkNotification: React.FC<LinkNotificationProps> = ({ message, route, onClose }) => {
  const { t } = useTranslation()

  const history = useHistory()

  const redirectTo = React.useCallback(
    (route: string) => {
      onClose()
      history.push(route)
    },
    [history, onClose],
  )

  return (
    <Snackbar
      open={true}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      style={{ marginLeft: SIDEBAR_NAVIGATION_WIDTH }}
      onClose={onClose}
    >
      <SnackbarContent
        style={{ background: '#232735' }}
        message={message}
        action={
          <Button color="secondary" id="discard" variant="text" onClick={() => redirectTo(route)}>
            {t('snackbar.click-button')}
          </Button>
        }
      />
    </Snackbar>
  )
}

export default LinkNotification
