import * as Types from '../types.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {"context":{"gateway":true}}
export type UnitConversionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Array<Types.InputMaybe<Types.GenericFilter>> | Types.InputMaybe<Types.GenericFilter>>;
}>;


export type UnitConversionsQuery = { __typename?: 'Query', unitConversions: { __typename?: 'ManyUnitConversionResult', entities: Array<{ __typename?: 'UnitConversion', id: string, multiplier: number, conversionExpression?: string | null, conversionExpressionTree?: ReturnType<JSON['parse']> | null, unit: { __typename?: 'Unit', id: string, name: string, tags: string }, convertToUnit: { __typename?: 'Unit', id: string, name: string, tags: string } }> } };


export const UnitConversionsDocument = gql`
    query UnitConversions($filter: [GenericFilter]) {
  unitConversions(filter: $filter) {
    entities {
      id
      multiplier
      conversionExpression
      unit {
        id
        name
        tags
      }
      convertToUnit {
        id
        name
        tags
      }
      conversionExpressionTree
    }
  }
}
    `;

/**
 * __useUnitConversionsQuery__
 *
 * To run a query within a React component, call `useUnitConversionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitConversionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitConversionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUnitConversionsQuery(baseOptions?: Apollo.QueryHookOptions<UnitConversionsQuery, UnitConversionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitConversionsQuery, UnitConversionsQueryVariables>(UnitConversionsDocument, options);
      }
export function useUnitConversionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitConversionsQuery, UnitConversionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitConversionsQuery, UnitConversionsQueryVariables>(UnitConversionsDocument, options);
        }
export type UnitConversionsQueryHookResult = ReturnType<typeof useUnitConversionsQuery>;
export type UnitConversionsLazyQueryHookResult = ReturnType<typeof useUnitConversionsLazyQuery>;
export type UnitConversionsQueryResult = Apollo.QueryResult<UnitConversionsQuery, UnitConversionsQueryVariables>;